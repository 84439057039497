<template>
  <ValidationObserver
    ref="downloadFiles"
  >
    <b-modal
      id="bulk-download-files"
      ref="bulk-download-files"
      centered
      title="Files Bulk Download"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @hidden="$emit('reset')"
      @show="prepareFiles"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <!--  Event  -->
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Files"
            >
              <v-select
                v-model="chosenFiles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="filesOptionsList"
                :placeholder="selectStatus"
                multiple
                :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          label="Receiving type"
        >
          <b-form-radio
            v-model="configs.isSendByEmail"
            class="mb-1"
            name="exportType"
            :value="false"
          >
            Download file
          </b-form-radio>
          <b-form-radio
            v-model="configs.isSendByEmail"
            name="exportType"
            :value="true"
          >
            Send file by email
          </b-form-radio>
        </b-form-group>

        <div
          v-if="configs.isSendByEmail"
        >
          <hr>
          <h5
            class="text-center py-1"
          >
            This process may take a while. You will receive an email with the zip file when complete. Please enter email below.
          </h5>
          <b-form-group
            class="mb-2"
          >
            <!-- Email -->
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email to send"
                label-for="email"
              >
                <b-form-input
                  v-model="configs.selectedEmail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </div>
      </b-overlay>

      <div
        v-if="validationErrors"
        class="form-group pt-1"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          :disabled="isLoading"
          @click="$refs['bulk-download-files'].hide()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!chosenFiles.length || isLoading"
          @click="handleForm"
        >
          Download
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BFormRadio, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'

export default {
  name: 'BulkDownloadFilesModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BOverlay,

    vSelect,
  },
  mixins: [downloadExportFile],
  props: {
    programId: {
      type: String,
      required: true,
    },
    programName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      configs: {
        selectedEmail: null,
        isSendByEmail: false,
      },
      chosenFiles: [],
      filesOptionsList: [],
      selectStatus: 'Loading...',
      isLoading: false,
      validationErrors: [],
    }
  },
  computed: {
    programNameCleaned() {
      return this.programName.replace(/\s/g, '-')
    },
  },
  setup() {
    const {
      getProgramFiles,
      fetchZipFiles,
    } = useProgramsSetting()

    return {
      getProgramFiles,
      fetchZipFiles,
    }
  },
  methods: {
    async prepareFiles() {
      this.validationErrors = []
      this.selectStatus = 'Loading...'
      this.chosenFiles = []
      this.filesOptionsList = []
      this.configs.selectedEmail = null
      this.configs.isSendByEmail = false

      await this.getProgramFiles(this.programId).then(response => {
        this.filesOptionsList = response
        this.selectStatus = 'Select Files'
      })
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.downloadFiles.validate().then(success => {
          if (success) {
            resolve(true)
            this.downloadZip()
          } else {
            reject()
          }
        })
      })
    },
    async downloadZip() {
      this.validationErrors = []
      this.isLoading = true
      const queryData = {
        program_id: this.programId,
        file_names: this.chosenFiles,
        selectedEmail: this.configs.selectedEmail,
        isSendByEmail: this.configs.isSendByEmail,
      }
      await this.fetchZipFiles(queryData).then(response => {
        if (!response) {
          this.validationErrors = ['Something went wrong']
          return
        }
        if (response && response.size === 0) {
          this.validationErrors = ['The Zip File is empty']
          return
        }
        if (response.status === 200 && this.configs.isSendByEmail === false) {
          this.downloadFile(response, this.programNameCleaned, 'zip')
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
